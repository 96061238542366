import React from "react";
import cx from "classnames";
import { Accordion, AccordionItem } from "brass-ui-kit";
import { uniqueId } from "lodash";

import * as styles from "./FaqWidget.module.scss";

interface FaqWidgetProps {
  content: { question: string; answer: string }[];
  theme?: "light" | "dark";
  className?: string;
}

const FaqWidget: React.FC<FaqWidgetProps> = ({ content, theme, className }) => {
  return (
    <section
      className={cx(
        styles.FaqWidget,
        [styles[`FaqWidget_${theme}`]],
        className
      )}
    >
      <div className={styles.FaqWidget_container}>
        <header
          className={cx(styles.FaqWidget_header, [
            styles[`FaqWidget_header_${theme}`],
          ])}
        >
          <h2 className={styles.FaqWidget_header_title}>
            Some of the things you may want to know
          </h2>
          <p
            className={cx(styles.FaqWidget_header_body, [
              styles[`FaqWidget_header_body_${theme}`],
            ])}
          >
            We answered questions so you don’t have to ask them.
          </p>
        </header>
        <div className={styles.FaqWidget_list}>
          <Accordion>
            {content.map(({ question, answer }) => {
              return (
                <AccordionItem
                  body={answer}
                  header={question}
                  className={cx(styles.FaqWidget_item, [
                    styles[`FaqWidget_item_${theme}`],
                  ])}
                  activeClassName={cx(styles.FaqWidget_item__active, [
                    styles[`FaqWidget_item__active_${theme}`],
                  ])}
                  key={uniqueId()}
                />
              );
            })}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default FaqWidget;
