import { KenyaLogo, NigeriaLogo } from "assets/media/svgs";
import {
  helpPageUrl,
  blogPageUrl,
  aboutPageUrl,
  pressPageUrl,
  retailPageUrl,
  guidesPageUrl,
  capitalPageUrl,
  industyPageUrl,
  careersPageUrl,
  whyBrassPageUrl,
  customersPageUrl,
  internetCompaniesPageUrl,
  launchpadPageUrl,
  ebookPageUrl,
  payrollPageUrl,
  savingsPageUrl,
} from "../../../data/pageUrl";

import {
  BBC_WEBSITE_URL,
  KENYA_HOMEPAGE_URL,
  BRASS_HOMEPAGE_URL,
} from "data/config";
import React from "react";

export interface SubItemParams {
  label: React.ReactNode;
  desc: string;
  route: string;
  isExternalUrl?: boolean;
}

export const DEFAULT_COUNTRY_ISO = "NG";

export const NAVBAR_CONFIG = {
  left: [
    {
      label: "Industry",
      route: industyPageUrl,
      subItems: [
        {
          label: "Retail businesses",
          desc: "Stores, restaurants, offline merchants",
          route: retailPageUrl,
        },
        {
          label: "Internet companies",
          desc: "Startups, Online, API driven companies",
          route: internetCompaniesPageUrl,
        },
      ],
    },
    {
      label: "Customers",
      route: customersPageUrl,
    },
    {
      label: "Products",
      route: industyPageUrl,
      subItems: [
        // {
        //   label: "Capital",
        //   desc: "Access growth financing for your business",
        //   route: capitalPageUrl,
        // },
        {
          label: "Payroll",
          desc: "The only payroll service your business will ever need",
          route: payrollPageUrl,
        },
        {
          label: "Launchpad",
          desc: "Support and resources to start your business",
          route: launchpadPageUrl,
        },
        {
          label: "Savings",
          desc: "Get closer to your business goals",
          route: savingsPageUrl,
        },
      ],
    },
    {
      label: "Company",
      route: industyPageUrl,
      subItems: [
        {
          label: "About us",
          desc: "Learn more about our mission",
          route: aboutPageUrl,
        },
        {
          label: "Why Brass?",
          desc: "Our story and our path forward",
          route: whyBrassPageUrl,
        },
        {
          label: "Careers",
          desc: "Explore opportunities with us",
          route: careersPageUrl,
        },
        {
          label: "Press resources",
          desc: "Press assets and guidelines",
          route: pressPageUrl,
        },
      ],
    },
  ],
  right: [
    {
      label: "Resources",
      route: industyPageUrl,
      subItems: [
        {
          label: "Blog",
          desc: "Stay updated on news and products",
          route: blogPageUrl,
        },
        {
          label: "Business Guide Book",
          desc: "The ultimate guide book for your business",
          route: ebookPageUrl,
        },
        {
          label: "Community",
          desc: "Join our community of entrepreneurs",
          route: BBC_WEBSITE_URL,
          isExternalUrl: true,
        },
        {
          label: "Guides",
          desc: "Everything your business needs to know",
          route: guidesPageUrl,
        },
        {
          label: "Help",
          desc: "Get support for your business",
          route: helpPageUrl,
        },
      ],
    },
  ],
  countrySwitcher: [
    {
      name: "Nigeria",
      isoCode: "NG",
      logo: <NigeriaLogo />,
      webUrl: BRASS_HOMEPAGE_URL,
    },
    {
      name: "Kenya",
      isoCode: "KE",
      logo: <KenyaLogo />,
      webUrl: KENYA_HOMEPAGE_URL,
    },
  ],
};
